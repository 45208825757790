import { DateTime } from "luxon"
export const calcClimateData = function(climateData, heatingLimitTemperature, indoorTemperature) {
  var monthlyData = {
    dayCounter: 1,
    temperature_air_mean: 0,
    temperature_air_min: 100,
    temperature_air_max: -100,
    heating_degree: 0,
    sunshine_duration: 0,
    precipitation_height: 0,
    energy_consumption: 0,
    energy_consumption_costs: 0,
    energy_consumption_metered: 0
  }
  var yearlyData = {
    dayCounter: 1,
    temperature_air_mean: 0,
    temperature_air_min: 100,
    temperature_air_max: -100,
    heating_degree: 0,
    sunshine_duration: 0,
    precipitation_height: 0,
    energy_consumption: 0,
    energy_consumption_costs: 0,
    energy_consumption_metered: 0
  }
  var heating_degree_sum = 0
  var energyPerHeatingDeg = 0

  var lastRoundHeatDeg = 0
  // sort data by date
  climateData.sort(function(a, b){
      const date1 = new Date(a.date)
      const date2 = new Date(b.date)
      return date1 - date2;
  })

  var climateDataYearly = []
  var climateDataMonthly = []
  var climateDataDaily = []


  // parse data and fill climateDataDaily, climateDataMonthly and climateDataYearly
  climateData.map(function(item, index, data) {

    var curMonth = DateTime.fromISO(item.date).toFormat('yyyy-MM')
    var curYear = DateTime.fromISO(item.date).toFormat('yyyy')
    var curDate = DateTime.fromISO(item.date).toFormat('yyyy-MM-dd')
    var avgTempMonth
    var avgTempYear
    var dateNextItem = 1
    var monthNextItem = 1
    var yearNextItem = 1
    var newItemDaily = {
      date: curDate
    }
    var newItemMonthly = {
      date: curMonth
    }
    var newItemYearly = {
      date: curYear
    }

    if (data[index+1] !== undefined) {
      dateNextItem = DateTime.fromISO(data[index+1].date).toFormat('yyyy-MM-dd')
      monthNextItem = DateTime.fromISO(data[index+1].date).toFormat('yyyy-MM')
      yearNextItem = DateTime.fromISO(data[index+1].date).toFormat('yyyy')
    }

    if (curDate != dateNextItem) {
      monthlyData.dayCounter++
      yearlyData.dayCounter++
    }

    var tempCelsius
    if (item['temperature_air_mean_200']) {
      tempCelsius = item['temperature_air_mean_200'] - 273.15
      monthlyData.temperature_air_mean += tempCelsius
      yearlyData.temperature_air_mean += tempCelsius
      newItemDaily['temperature_air_mean_200'] = tempCelsius

      var heatingDeg
      if (tempCelsius < heatingLimitTemperature) {
        heatingDeg = indoorTemperature - tempCelsius
      } else {
        heatingDeg = 0
      }
      newItemDaily['heating_degree'] = heatingDeg

      heating_degree_sum += lastRoundHeatDeg

      lastRoundHeatDeg = heatingDeg

      newItemDaily['heating_degree_sum'] = heating_degree_sum
      
      monthlyData.heating_degree += heatingDeg
      yearlyData.heating_degree += heatingDeg
    }
    
    if (item['temperature_air_min_200'] !== undefined) {
      tempCelsius = item['temperature_air_min_200'] - 273.15
      newItemDaily['temperature_air_min_200'] = tempCelsius
      if (tempCelsius < monthlyData.temperature_air_min) {
        monthlyData.temperature_air_min = tempCelsius
      }
      if (tempCelsius < yearlyData.temperature_air_min) {
        yearlyData.temperature_air_min = tempCelsius
      }
    }

    if (item['temperature_air_max_200'] !== undefined) {
      tempCelsius = item['temperature_air_max_200'] - 273.15
      newItemDaily['temperature_air_max_200'] = tempCelsius
      if (tempCelsius > monthlyData.temperature_air_max) {
        monthlyData.temperature_air_max = tempCelsius
      }
      if (tempCelsius > yearlyData.temperature_air_max) {
        yearlyData.temperature_air_max = tempCelsius
      }
    }

    if (item['sunshine_duration'] !== undefined) {
      var sunHours = item['sunshine_duration'] / 3600
      newItemDaily['sunshine_duration'] = sunHours
      monthlyData.sunshine_duration += sunHours
      yearlyData.sunshine_duration += sunHours
    }

    if (item['precipitation_height'] !== undefined) {
      newItemDaily['precipitation_height'] = item['precipitation_height']
      monthlyData.precipitation_height += item['precipitation_height']
      yearlyData.precipitation_height += item['precipitation_height']
    }
    climateDataDaily.push(newItemDaily)

    if (curMonth != monthNextItem) {
      avgTempMonth = monthlyData.temperature_air_mean / monthlyData.dayCounter
      newItemMonthly['temperature_air_mean_200'] = avgTempMonth
      monthlyData.temperature_air_mean = 0

      newItemMonthly['heating_degree'] = monthlyData.heating_degree
      monthlyData.heating_degree = 0

      newItemMonthly['temperature_air_min_200'] = monthlyData.temperature_air_min
      monthlyData.temperature_air_min = 100

      newItemMonthly['temperature_air_max_200'] = monthlyData.temperature_air_max
      monthlyData.temperature_air_max = -100

      newItemMonthly['sunshine_duration'] = monthlyData.sunshine_duration
      monthlyData.sunshine_duration = 0

      newItemMonthly['precipitation_height'] = monthlyData.precipitation_height
      monthlyData.precipitation_height = 0

      monthlyData.dayCounter = 0
      climateDataMonthly.push(newItemMonthly)
    }
    
    if (curYear != yearNextItem) {
      avgTempYear = yearlyData.temperature_air_mean / yearlyData.dayCounter
      newItemYearly['temperature_air_mean_200'] = avgTempYear
      yearlyData.temperature_air_mean = 0

      newItemYearly['heating_degree'] = yearlyData.heating_degree
      yearlyData.heating_degree = 0

      newItemYearly['temperature_air_min_200'] = yearlyData.temperature_air_min
      yearlyData.temperature_air_min = 100

      newItemYearly['temperature_air_max_200'] = yearlyData.temperature_air_max
      yearlyData.temperature_air_max = -100

      newItemYearly['sunshine_duration'] = yearlyData.sunshine_duration
      yearlyData.sunshine_duration = 0

      newItemYearly['precipitation_height'] = yearlyData.precipitation_height
      yearlyData.precipitation_height = 0

      yearlyData.dayCounter = 0
      climateDataYearly.push(newItemYearly)
    }
  })
  // calculate energy per heating degree from reference period
  /*
  var beginReferencePeriod = DateTime.fromISO(EnCoRefPeriod[0]).plus({day: -1}).toFormat('yyyy-MM-dd')
  var startHeatingDeg = climateDataDaily.find(item => item.date == beginReferencePeriod) 
  var endReferencePeriod = DateTime.fromISO(EnCoRefPeriod[1]).plus({day: 0}).toFormat('yyyy-MM-dd')
  var endHeatingDeg = climateDataDaily.find(item => item.date == endReferencePeriod) 

  energyPerHeatingDeg = EnCoAmount / (endHeatingDeg['heating_degree_sum'] - startHeatingDeg['heating_degree_sum']) 

  // save energy_consumption
  climateDataDaily.map(function(item) {
    item['energy_consumption'] = item['heating_degree'] * energyPerHeatingDeg
    item['energy_consumption_costs'] = item['energy_consumption'] * EnCoPrice
    item['energy_consumption_metered'] = 0
  })

  // calculate energy consunption by metered data and save to climateDataDaily
  /*
  meterReadings.map(function(item, index, data) {
    var begin
    var end

    if (data[index+1] !== undefined) {
      begin = item.date
      end = data[index+1].date
      var startHeatingDeg = climateDataDaily.find(function(item) {
        if (item.date == this) {
          return item
        }
      }, begin)
      var endHeatingDeg = climateDataDaily.find(function(item) {
        if (item.date == this) {
          return item
        }
      }, end)
      var energyPerHeatingDegPeriod = (data[index+1].value - item.value) / (endHeatingDeg['heating_degree_sum'] - startHeatingDeg['heating_degree_sum']) 
      data[index].energyPerHeatingDeg = energyPerHeatingDegPeriod
      
      climateDataDaily.find(function (item) {
        if (item.date >= begin && item.date <= end) {
          var energy_consumption_metered = energyPerHeatingDegPeriod * item['heating_degree']
          item['energy_consumption_metered'] = energy_consumption_metered
        } 
      })
      
    }
  })
  */

  // sort data by date
  climateDataDaily.sort(function(a, b){
      const date1 = new Date(a.date)
      const date2 = new Date(b.date)
      return date1 - date2;
  })

  // parse climateDataDaily and save energy consumption in monthly and yearly data
  climateDataDaily.map(function(item, index, data) {

    var curMonth = DateTime.fromISO(item.date).toFormat('yyyy-MM')
    var curYear = DateTime.fromISO(item.date).toFormat('yyyy')

    var monthNextItem = 1
    var yearNextItem = 1

    if (data[index+1] !== undefined) {
      monthNextItem = DateTime.fromISO(data[index+1].date).toFormat('yyyy-MM')
      yearNextItem = DateTime.fromISO(data[index+1].date).toFormat('yyyy')
    }
    
    monthlyData.energy_consumption += item['energy_consumption']
    yearlyData.energy_consumption += item['energy_consumption']

    monthlyData.energy_consumption_costs += item['energy_consumption_costs']
    yearlyData.energy_consumption_costs += item['energy_consumption_costs']

    monthlyData.energy_consumption_metered += item['energy_consumption_metered']
    yearlyData.energy_consumption_metered += item['energy_consumption_metered']

    if (curMonth != monthNextItem) {
      var itemMonth = climateDataMonthly.find((item) => item.date == curMonth)
      itemMonth['energy_consumption'] = monthlyData.energy_consumption
      itemMonth['energy_consumption_costs'] = monthlyData.energy_consumption_costs
      monthlyData.energy_consumption = 0
      monthlyData.energy_consumption_costs = 0

      itemMonth['energy_consumption_metered'] = monthlyData.energy_consumption_metered
      monthlyData.energy_consumption_metered = 0
    }
    if (curYear != yearNextItem) {
      var itemYear = climateDataYearly.find((item) => item.date == curYear)
      itemYear['energy_consumption'] = yearlyData.energy_consumption
      itemYear['energy_consumption_costs'] = yearlyData.energy_consumption_costs
      yearlyData.energy_consumption = 0
      yearlyData.energy_consumption_costs = 0

      itemYear['energy_consumption_metered'] = yearlyData.energy_consumption_metered
      yearlyData.energy_consumption_metered = 0
    }
    
  })
  return {climateDataDaily, climateDataMonthly, climateDataYearly, energyPerHeatingDeg}
}


export const getHeatingDegreesPeriod = function(climateDataDaily, heatingLimitTemperature, indoorTemperature, startDate, endDate, consumption, period) {
  climateDataDaily.sort(function(a, b){
    const date1 = new Date(a.date)
    const date2 = new Date(b.date)
    return date1 - date2;
  })

  var heating_degree_sum = 0
  var items = climateDataDaily.filter(item => (new Date(item.date) >= new Date(startDate) && new Date(item.date) <= new Date(endDate)))
  
  if (items) {
    items.map(function(item) {
      var heatingDeg
      var tempCelsius = item['temperature_air_mean_200']
      if (tempCelsius < heatingLimitTemperature) {
        heatingDeg = indoorTemperature - tempCelsius
      } else {
        heatingDeg = 0
      }
      heating_degree_sum += heatingDeg
      item['heating_degree_' + period] = heatingDeg
      
      return item
    })

    var energyPerGTZ = consumption / heating_degree_sum
    items.map(function(item) {
      var energyPerDay = item['heating_degree_' + period] * energyPerGTZ  
      item['energy_consumption_' + period] = Math.round(energyPerDay * 10)/10
      return item
    })
  }
  
  return {heating_degree_sum: Math.round(heating_degree_sum), energyPerGTZ: energyPerGTZ}
}

export const getConsumptionVariants = function(climateDataDaily, heatingLimitTemperature, indoorTemperature, startDate, endDate, energyPerGTZ, consumption) {
  var items = climateDataDaily.filter(item => (new Date(item.date) >= new Date(startDate) && new Date(item.date) <= new Date(endDate)))

  var heating_degree_sum = {}
  for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
    heating_degree_sum[i] = 0
  }

  if (items) {
    items.map(function(item) {
      var tempCelsius = item['temperature_air_mean_200']
      if (tempCelsius < heatingLimitTemperature) {
        for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
          heating_degree_sum[i] = heating_degree_sum[i] + (i - tempCelsius)
        }
      } 
    })
    var returnData = [] 
    for (let i = indoorTemperature-3; i <= indoorTemperature+3; i++ ) {
      var dataObj = {}
      dataObj.indoorTemperature = i
      dataObj.heating_degree_sum = Math.round(heating_degree_sum[i])
      dataObj.consumption = Math.round(energyPerGTZ * heating_degree_sum[i] * 10)/10
      dataObj.diff = Math.round((dataObj.consumption / consumption - 1) * 1000) / 10
      returnData.push(dataObj)
    }
    return returnData
  }  
}