<template>
  <div>
    <v-select
      v-model="value.resolution"
      :items="resolutionSelect"
      label="Auflösung"
      return-object
    ></v-select>
    <v-expansion-panels focusable mandatory>
      <v-expansion-panel
        v-for="(chart,i) in value.charts"
        :key="i"
      >
        <v-expansion-panel-header >
        {{chart.parameter.text}}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-3">
          <v-select
            v-model="chart.parameter"
            :items="paramsSelect"
            label="Parameter"
            return-object
          ></v-select>
          <v-select
            v-model="chart.chart"
            :items="chartSelect"
            label="Diagramm"
            return-object
          ></v-select>
          <v-select
            v-model="chart.scale"
            :items="[{
              text: 'Skala links anzeigen',
              value: 'left'
            },{
              text: 'Skala rechts anzeigen',
              value: 'right'
            },{
              text: 'Keine Skala anzeigen',
              value: 'none'
            }]"
            label="Skala"
            return-object
          ></v-select>
          <v-select
            v-model="chart.selectedYear"
            v-if="(value.resolution.value == 'day' || value.resolution.value == 'month') && i > 0"
            :items="yearSelect"
            label="Zeitraum"
            return-object
          ></v-select>
          <div class="v-label theme--light d-flex align-center">
            <v-swatches
              v-model="chart.color"
              popover-x="right"
              swatches="text-advanced"
              :trigger-style="{ width: '40px', height: '26px' }"
              class="mr-2"
            ></v-swatches> 
             Farbe
         </div>
          <v-switch
            v-model="chart.showLabel"
            label="Werte in Chart anzeigen"
            hide-details
          ></v-switch>
          <div class="d-flex justify-end mt-3">
            <v-spacer></v-spacer>
            <v-btn 
              icon
              color="error"
              v-if="i > 0"
              @click="deleteChart(i)"
            >
              <v-icon>
                mdi-minus-circle
              </v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-content>
        
      </v-expansion-panel>

    </v-expansion-panels>
    <div class="d-flex justify-end mt-3">
      <v-btn
        icon
        color="info"
        @click="addChart()"
      >
        <v-icon >mdi-plus-box</v-icon>
 
      </v-btn>
    </div>
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
      hide-details
      class="mb-4"
    ></v-switch>
  </div>
</template>

<script>
import { DateTime } from "luxon"
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  export default {
    components: { VSwatches },
    data: () => ({
      chartSelect: [{
        text: 'Liniendiagramm',
        value: 'line'
      },{
        text: 'Balkendiagramm',
        value: 'bar'
      }],
      dialog: false,
      colormenu: '',
    }),
    props: [
      'value',
      'config',
      'wetterCockpit'
    ],
    watch: {
      value: {
        handler() {
          this.$emit('input', this.value)
        },
        deep: true
      }
    },  
    computed: {
      getTicks() {
        return this.ticks[this.value.resolution.value]
      },
      getTicksLength() {
        return this.ticks[this.value.resolution.value].length
      },
      resolutionSelect() {
        var resolutionSelect = []
        resolutionSelect.push({
          text: 'Tage',
          value: 'day'
        },{
          text: 'Monate',
          value: 'month'
        },{
          text: 'Jahre',
          value: 'year'
        },{
          text: this.wetterCockpit.settings.periods.period1.name,
          value: 'period1'
        },{
          text: this.wetterCockpit.settings.periods.period2.name,
          value: 'period2'
        },{
          text: this.wetterCockpit.settings.periods.period3.name,
          value: 'period3'
        },{
          text: this.wetterCockpit.settings.periods.period4.name,
          value: 'period4'
        },{
          text: this.wetterCockpit.settings.periods.period5.name,
          value: 'period5'
        })
        return resolutionSelect
      },
      paramsSelect() {
        var paramsSelect = [{
          text: 'Durchschnittstemperatur', 
          value: 'temperature_air_mean_200',
          unit: '°C'
        },{
          text: 'Höchsttemperatur',
          value: 'temperature_air_max_200',
          unit: '°C'
        },{
          text: 'Tiefsttemperatur',
          value: 'temperature_air_min_200',
          unit: '°C'
        },{
          text: 'Niederschlag',
          value: 'precipitation_height',
          unit: 'l'
        },{
          text: 'Sonnenstunden',
          value: 'sunshine_duration',
          unit: 'h'
        }]
        if (this.value.resolution.value.startsWith('period')) {
          paramsSelect.push({
            text: 'Gradtagzahl',
            value: 'heating_degree_' + this.value.resolution.value,
            unit: 'kD'
          },{
            text: 'Energieverbrauch',
            value: 'energy_consumption_' + this.value.resolution.value,
            unit: 'kWh'
          })
        } else {
          paramsSelect.push({
            text: 'Gradtagzahl',
            value: 'heating_degree',
            unit: 'kD'
          })
        }
        return paramsSelect
      },
      yearSelect() {
        var startYear = DateTime.fromISO(this.config.startDateClimateData).toFormat('yyyy')
        var endYear = DateTime.now().toFormat('yyyy')
        var yearSelect = [{
          text: 'ausgewähltes Jahr',
          value: 'standard'
        },{
          text: 'Vorjahr des ausgewählten Jahrs',
          value: 'lastYear'
        }]
        for (let i = startYear; i <= endYear; i++) {
          yearSelect.push({
            text: i,
            value: i
          })
        }
        return yearSelect
      }
    },
    methods: {
      deleteChart(i) {
        this.$delete(this.value.charts, i)
        //this.value.charts.splice(i, 1)
      },
      addChart() {
        this.value.charts.push({
          color: '#F44336',
          parameter: {
            text: 'Durchschnittstemperatur', 
            value: 'temperature_air_mean_200',
            unit: '°C'
          },
          scale: {
            text: 'Skala links anzeigen',
            value: 'left'
          },
          chart: {
            value: 'bar'
          },
          selectedYear: {
            text: 'ausgewähltes Jahr',
            value: 'standard'
          },
          showLabel: true
        })
      },
      up(i) {
        if (i > 0) {
          const element = this.value.charts.splice(i, 1)[0]
          this.value.charts.splice(i-1, 0, element)
        }
      },
      down(i) {
        if (i < this.value.charts.length) {
          const element = this.value.charts.splice(i, 1)[0]
          this.value.charts.splice(i+1, 0, element)
        }
      }
    }
    
  }

</script>
<style>

</style>
