<template>
    <div v-if="widgetSettings.mode.value=='input'">
      <v-card-subtitle class="pa-0 mb-4 font-weight-bold position-relative">
         {{widgetSettings.periodName.text}} 
      </v-card-subtitle>
      <v-card-text class="pa-0">
        <div class="mt-2 periodWidgetForm grid">
          <v-menu
            v-model="periodPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="periodDisplay"
                readonly
                label="Zeitraum"
                v-bind="attrs"
                v-on="on"
                hide-details
                class="yellow lighten-5"
                placeholder="Zeitraum wählen"
                persistent-placeholder
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="value.settings.periods[widgetSettings.periodName.value].period"
              @change="periodPicker = false;"
              no-title
              range
              :max="now"
              locale = "de-de"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            label="Energieverbrauch"
            v-model="value.settings.periods[widgetSettings.periodName.value].consumption"
            hide-details
            class="yellow lighten-5"
            type="number"
          >
            <template v-slot:append>
              kWh
              </template>
            </v-text-field>

          <div class="yellow lighten-5 boxSlider" v-if="widgetSettings.showDegreeSettings">
            <div class="text-caption labelSlider">Heizgrenztemperatur</div>
            <v-slider
              v-model="value.settings.periods[widgetSettings.periodName.value].heatingLimitTemperature"
              :tick-labels="heatingLimitTemperatureRangeTicks"
              step="1"
              ticks="always"
              tick-size="4"
              hide-details
              :min="config.heatingDegreeSettings.heatingLimitTemperatureRange[0]"
              :max="config.heatingDegreeSettings.heatingLimitTemperatureRange[1]"
              
            ></v-slider>
          </div>
          <div class="yellow lighten-5 boxSlider" v-if="widgetSettings.showDegreeSettings">
            <div class="text-caption labelSlider">Innenraumtemperatur</div>
            <v-slider
              v-model="value.settings.periods[widgetSettings.periodName.value].indoorTemperature"
              :tick-labels="indoorTemperatureRangeTicks"
              step="1"
              ticks="always"
              tick-size="4"
              hide-details
              :min="config.heatingDegreeSettings.indoorTemperatureRange[0]"
              :max="config.heatingDegreeSettings.indoorTemperatureRange[1]"
              
            ></v-slider>
          </div>
        </div>
      </v-card-text>
    </div>
    <div v-else class="fill-height">
      <div v-if="!startDate && !endDate" class="d-flex flex-column fill-height justify-center">
        <div class="text-h5 pa-0 text-center">
          {{widgetSettings.periodName.text}}
        </div>
        <div class="text-h6 pa-0 text-center">
         Keine Daten
        </div>
      </div>
      <div v-else class="fill-height">
        <div v-if="widgetSettings.mode.value=='energyPerGTZ'" class="d-flex flex-column fill-height justify-space-between">
          <v-card-subtitle class="pa-0 mb-4 font-weight-bold">
            {{widgetSettings.periodName.text}} 
          </v-card-subtitle>
          <div>
            <div class="text-h3 text-center font-weight-bold">{{energyPerGTZRounded}} kWh</div>
            <div class="text-h5 pa-0 text-center">
            pro Gradtag
            </div>
            <div class="text-h6 pa-0 text-center">Summe Gradtage: {{heatingDegrees}}</div>
          </div>
          <div class="pa-4"></div>
        </div>

        <div v-if="widgetSettings.mode.value=='consumptionVariants'" class="fill-height">
          <v-chart 
            :option="getConsumptionVariantsChart()" 
            :init-options="initOptions" 
            autoresize 
          />  
        </div>
        
        <v-card v-if="widgetSettings.mode.value=='compare'" flat :color="comparisonResult > 0 ? colorRed:colorGreen"  class="pa-4 d-flex flex-column fill-height justify-space-between" dark>
          <v-card-subtitle class="pa-0 font-weight-bold">
            Vergleich von 
            {{widgetSettings.periodName.text}}
            und 
            {{widgetSettings.compareTo.text}}
          </v-card-subtitle>
          <div v-if="comparisonResult > 0">
            <div class="text-h3 text-center font-weight-bold">
              <v-icon color="white" size="48" class="pb-2">
                mdi-arrow-up
              </v-icon>
              +{{comparisonResult}}%
            </div>
            <div class="text-h6 pa-0 text-center">Der Verbrauch ist gestiegen</div>
          </div>
          <div v-if="comparisonResult < 0">
            <div class="text-h3 text-center font-weight-bold">
              <v-icon color="white" size="48" class="pb-2">
                mdi-arrow-down
              </v-icon>
              {{comparisonResult}}%
            </div>
            <div class="text-h6 pa-0 text-center">Der Verbrauch ist gesunken</div>
          </div>
          <div></div>
        </v-card>
      </div>
    </div>

 
    
</template>

<script>
  import { DateTime } from "luxon"
  import {getHeatingDegreesPeriod, getConsumptionVariants} from './../../climateFunctions.js'
  import VChart from "vue-echarts"
  import 'echarts/i18n/langDE'

  export default {
    components: {
      VChart
    },
    data: () => ({
      periodPicker: false,
      period: [null, null],
      consumption: 0,
      heatingDegrees: 0,
      energyPerGTZ: 0,
      energyPerGTZofCompared: 0,
      chartData: [],
      dataConsumptionVariantsX: [],
      dataConsumptionVariantsY: [],
      dataConsumptionVariantsGTZY: [],
      initOptions: {
        locale: 'de',
        height: 'auto'
      },
      colorRed: '#E53935',
      colorGreen: '#43A047'
    }),
    props: [
      'id',
      'widgetSettings',
      'widget',
      'value',
      'editmode',
      'config',
      'climateDataDaily'
    ],
    mounted() {
      this.getHeatingDegrees()
      this.getHeatingDegreesOfCompared()
      this.getConsumptionVariants()
    },
    watch: {
      value: {
        handler() {
          this.getHeatingDegrees()
          this.getHeatingDegreesOfCompared()
          this.getConsumptionVariants()
        },
        deep: true
      },
      climateDataDaily(newValue, oldValue) {
        if (newValue != oldValue) {
          this.getHeatingDegrees()
          this.getHeatingDegreesOfCompared()
          this.getConsumptionVariants()
        }
        
      }
    },
    computed: {
      now() {
        return DateTime.now().plus({days: -1}).toFormat('yyyy-MM-dd')
      },
      heatingLimitTemperatureRangeTicks() {
        var ticks = []
        for (let i = this.config.heatingDegreeSettings.heatingLimitTemperatureRange[0]; i <= this.config.heatingDegreeSettings.heatingLimitTemperatureRange[1]; i++) {
          ticks.push(i + '°')
        }
        return ticks
      },
      indoorTemperatureRangeTicks() {
        var ticks = []
        for (let i = this.config.heatingDegreeSettings.indoorTemperatureRange[0]; i <= this.config.heatingDegreeSettings.indoorTemperatureRange[1]; i++) {
          ticks.push(i + '°')
        }
        return ticks
      },
      startDate() {
        var selectedDates = this.value.settings.periods[this.widgetSettings.periodName.value].period
        if (selectedDates[0] < selectedDates[1]) {
          return selectedDates[0]
        } else {
          return selectedDates[1]
        }
      },
      endDate() {
        var selectedDates = this.value.settings.periods[this.widgetSettings.periodName.value].period
        if (selectedDates[0] > selectedDates[1]) {
          return selectedDates[0]
        } else {
          return selectedDates[1]
        }
      },
      startDateOfCompared() {
        var selectedDates = this.value.settings.periods[this.widgetSettings.compareTo.value].period
        if (selectedDates[0] < selectedDates[1]) {
          return selectedDates[0]
        } else {
          return selectedDates[1]
        }
      },
      endDateOfCompared() {
        var selectedDates = this.value.settings.periods[this.widgetSettings.compareTo.value].period
        if (selectedDates[0] > selectedDates[1]) {
          return selectedDates[0]
        } else {
          return selectedDates[1]
        }
      },
      periodDisplay () {
        var dateDisplay
        if (this.startDate) {
          dateDisplay = DateTime.fromISO(this.startDate).toFormat('dd.MM.yyyy')
        }
        if (this.endDate) {
          dateDisplay += " - " + DateTime.fromISO(this.endDate).toFormat('dd.MM.yyyy')
        }

        return dateDisplay
      },
      periodLength() {
        var end = DateTime.fromISO(this.startDate)
        var start = DateTime.fromISO(this.endDate)

        return start.diff(end, 'days').toObject().days
      },
      comparisonResult() {
        var diff =  this.energyPerGTZofCompared / this.energyPerGTZ - 1
        return Math.round(diff * 100)
      },
      energyPerGTZRounded() {
        return Math.round(this.energyPerGTZ * 10)/10
      }
    },
    methods: {
      getHeatingDegrees() {
        if (this.startDate && this.endDate) {
          var result = getHeatingDegreesPeriod(
            this.climateDataDaily, 
            this.value.settings.periods[this.widgetSettings.periodName.value].heatingLimitTemperature, 
            this.value.settings.periods[this.widgetSettings.periodName.value].indoorTemperature, 
            this.startDate, 
            this.endDate,
            this.value.settings.periods[this.widgetSettings.periodName.value].consumption,
            this.widgetSettings.periodName.value
          )
          this.heatingDegrees = result.heating_degree_sum
          this.energyPerGTZ = result.energyPerGTZ
        }
      },
      getHeatingDegreesOfCompared() {
        if (this.startDateOfCompared && this.endDateOfCompared) {
          var result = getHeatingDegreesPeriod(
            this.climateDataDaily, 
            this.value.settings.periods[this.widgetSettings.compareTo.value].heatingLimitTemperature, 
            this.value.settings.periods[this.widgetSettings.compareTo.value].indoorTemperature, 
            this.startDateOfCompared, 
            this.endDateOfCompared,
            this.value.settings.periods[this.widgetSettings.compareTo.value].consumption,
            this.widgetSettings.compareTo.value
          )
          this.energyPerGTZofCompared = result.energyPerGTZ
        }
      },
      getConsumptionVariants() {
        if (this.startDate && this.endDate) {
          var result = getConsumptionVariants(
            this.climateDataDaily, 
            this.value.settings.periods[this.widgetSettings.periodName.value].heatingLimitTemperature, 
            this.value.settings.periods[this.widgetSettings.periodName.value].indoorTemperature, 
            this.startDate, 
            this.endDate,
            this.energyPerGTZ,
            this.value.settings.periods[this.widgetSettings.periodName.value].consumption
          )
          
          var dataConsumptionVariantsY = result.map(function(item) {
            var color = this.colorGreen
            if (item.diff > 0) {
              color = this.colorRed
            }
            var dataObj = {
              value: item.diff,
              itemStyle: {
                color: color
              }
            }
            return dataObj
          }, this)
          this.dataConsumptionVariantsY = dataConsumptionVariantsY

          var dataConsumptionVariantsX = []
          
          for (let i = this.value.settings.periods[this.widgetSettings.periodName.value].indoorTemperature-3; i <= this.value.settings.periods[this.widgetSettings.periodName.value].indoorTemperature+3; i++ ) {
            dataConsumptionVariantsX.push(i + '°')
          }
          this.dataConsumptionVariantsX = dataConsumptionVariantsX

          var dataConsumptionVariantsGTZY = result.map(function(item) {
     
            return item.heating_degree_sum
          })
          this.dataConsumptionVariantsGTZY = dataConsumptionVariantsGTZY          
        }
      },
      getConsumptionVariantsChart() {
        var chartSettings = {
          title: {
            show: true,
            left: 'left',
            top: 0,
            textStyle: {
              fontFamily: '"Roboto Slab", Helvetica, Arial, sans-serif',
              fontSize: '14px'
            },
            text: this.widgetSettings.periodName.text + ': Effekt bei Änderung der Innentemperatur'
          },
          grid: {
            left: 0,
            right: 5,
            top: 40,
            bottom: 5,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              label: {
                
              }
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: ''
            },
            show: true,
          },
          xAxis: { 
            type: 'category',
            data: this.dataConsumptionVariantsX,
          },
          series: {
            type: 'bar',
            data: this.dataConsumptionVariantsY,
            label: {
              show: true,
              position: 'top',
              color: '#000',
              formatter: '{c}%'
            }
          }

        }
        return chartSettings
      }
      
    }
  }
</script>
<style>
.v-application p {
    margin-bottom: 6px;
}
.grid {
  display: grid;
  /*grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));*/
  grid-gap: 20px;
}
.position-relative {
  position: relative;
}
.zeitraumIcon {
  position: absolute !important;
  top: 0;
  right: 0;
}
.periodWidgetForm .v-slider__tick-label {
  font-size: 0.675rem;
}
.labelSlider {
  color: rgba(0, 0, 0, 0.6);
  line-height: 100% !important;
}
.v-application .periodWidgetForm > .boxSlider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  padding-bottom: 12px;
}
</style>
