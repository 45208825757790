<template>
  <div>
    <v-select
      v-model="value.resolution"
      :items="resolutionSelect"
      label="Auflösung"
      return-object
    ></v-select>
    Spalten:
    <v-checkbox
      v-for="(item, i) in paramsSelect"
      :key="i"
      v-model="value.parameter"
      :label="item.text"
      :value="item"
      dense
    ></v-checkbox>
    
    <v-switch
      v-model="value.showFrame"
      label="Im Rahmen anzeigen"
    ></v-switch>   
  </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      colormenu: false,
      paramsSelect: [{
        text: 'Durchschnittstemperatur', 
        value: 'temperature_air_mean_200',
        unit: '°C'
      },{
        text: 'Höchsttemperatur',
        value: 'temperature_air_max_200',
        unit: '°C'
      },{
        text: 'Tiefsttemperatur',
        value: 'temperature_air_min_200',
        unit: '°C'
      },{
        text: 'Gradtagzahl',
        value: 'heating_degree',
        unit: 'kD'
      },{
        text: 'Niederschlag',
        value: 'precipitation_height',
        unit: 'l'
      },{
        text: 'Sonnenstunden',
        value: 'sunshine_duration',
        unit: 'h'
      }],
      resolution: {
        text: 'Tage',
        value: 'day'
      },
    }),
    props: [
      'value',
      'wetterCockpit'
    ],
    watch: {
      value: {
        handler() {
          this.$emit('input', this.value)
        },
        deep: true
      }
    },  
    computed: {
      resolutionSelect() {
        return [{
          text: 'Tage',
          value: 'day'
        },{
          text: 'Monate',
          value: 'month'
        },{
          text: 'Jahre',
          value: 'year'
        },{
          text: this.wetterCockpit.settings.periods.period1.name,
          value: 'period1'
        },{
          text: this.wetterCockpit.settings.periods.period2.name,
          value: 'period2'
        },{
          text: this.wetterCockpit.settings.periods.period3.name,
          value: 'period3'
        },{
          text: this.wetterCockpit.settings.periods.period4.name,
          value: 'period4'
        },{
          text: this.wetterCockpit.settings.periods.period5.name,
          value: 'period5'
        }]
      }
    },
    methods: {
    }
    
  }

</script>
<style>

</style>
